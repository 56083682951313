




import { Component, Vue, Prop } from "vue-property-decorator";
import ko from "knockout";
import koHtmlAndViewModel from "Components/compare-vessels/compare-vessels";
import { ExtendedVessel } from "@/types/Vessel";

@Component
export default class CompareVesselsWrapper extends Vue {
  @Prop() vessels!: ExtendedVessel[];
  instance: any = {};

  mounted(): void {
    const param = {
      vessels: this.vessels,
    };

    this.instance = new koHtmlAndViewModel.viewModel(param);
    const { template } = koHtmlAndViewModel;
    this.$el.innerHTML = template;
    ko.applyBindings(this.instance, this.$el);
  }

  beforeDestroy(): void {
    ko.cleanNode(this.$el);
    this.instance.dispose();
  }
}
