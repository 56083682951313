





























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import VesselsModule from "@/store/clients/Vessels.module";
import IncidentsModule from "@/store/clients/Incidents.module";
import VesselGroupsModule from "@/store/clients/VesselGroups.module";
import { ExtendedVessel, Vessel } from "@/types/Vessel";
import { Incident } from "@/types/Incident";
import urlHelper from "@/Scripts/utilities/url-helper";
import { VesselGroup } from "@/types/vesselGroup";
import performanceStatusHelper from "@/Scripts/utilities/performance-status-helper";
import dateHelper from "@/Scripts/utilities/date-helper";
import Map from "@/components/Map.vue";
import CompareVesselsWrapper from "@/components/knockoutWrappers/CompareVesselsWrapper.vue";
import events from "App/events";

const Vessels = getModule(VesselsModule, store);
const Incidents = getModule(IncidentsModule, store);
const VesselGroups = getModule(VesselGroupsModule, store);

@Component({
  components: {
    Map,
    CompareVesselsWrapper,
  },
})
export default class VesselOverview extends Vue {
  selectedVessels: ExtendedVessel[] = [];
  vesselCompareNavigateHomeBinding: any = null;
  showCompare = false;
  sortBy = "";
  sortDesc = false;
  itemsPerPage = 10;
  isMapEnabled = false;
  search = "";
  areVesselGroupsLoading = true;
  selectedGroupIds: number[] = [];
  // Used to refresh map when filtered vessels change.
  mapKey = 0;
  headers = [
    {
      text: "Vessel Name",
      value: "vessel.name",
      sortable: true,
      width: "25%",
    },
    {
      text: "Last Data Sent",
      value: "vessel.lastExportDate",
      sortable: true,
      width: "20%",
    },
    {
      text: "Performance Status",
      value: "performanceStatus",
      sortable: true,
      width: "30%",
    },
    {
      text: "Open Incidents",
      value: "openIncidents",
      sortable: true,
      width: "25%",
    },
  ];

  performanceStatusTooltipText =
    `<div><span>${this.getPerformanceStatusIcon("Ok")} <b>[OK]</b> The ship is performing within an accepted deviation from baseline level.</span> <br>` +
    `<span>${this.getPerformanceStatusIcon("Observe")} <b>[OBSERVE]</b> The ship is performing ok, but the trend is moving towards an unaccepted deviation from baseline.</span> <br>` +
    `<span>${this.getPerformanceStatusIcon("NotOk")} <b>[NOT OK]</b> The ship performance is poor compared to baseline. It is recommended to perform some action to improve performance.</span> <br>` +
    `<span>${this.getPerformanceStatusIcon("BenchMarking")} <b>[BENCHMARKING]</b> The ship has just started a new trend period and is establishing its baseline level.</span></div>`;

  get areVesselsLoading(): boolean {
    return Vessels.vesselsLoadingState;
  }

  get tableData(): VesselOverviewTableRowData[] {
    const extendedVessels = this.filteredVessels;
    return extendedVessels.map(v => {
      const openWarningIncidents = this.getOpenWarningIncidents(v.id);
      const openCriticalIncidents = this.getOpenCriticalIncidents(v.id);
      const tableRow: VesselOverviewTableRowData = {
        vessel: v,
        performanceStatus: v.performance?.performanceStatus,
        openIncidents: this.isNotificationsEnabled(v) ? openWarningIncidents + openCriticalIncidents : -1,
        openWarningIncidents: openWarningIncidents,
        openCriticalIncidents: openCriticalIncidents,
      };
      return tableRow;
    });
  }

  get areAllVesselsSelected(): boolean {
    return this.selectedVessels.length === this.filteredVessels.length;
  }

  get filteredVessels(): ExtendedVessel[] {
    let filteredVessels = this.extendedVessels;
    if (this.selectedGroupIds.length > 0) {
      const selectedGroupsVesselIds = this.vesselGroups.filter(g => this.selectedGroupIds.includes(g.id)).flatMap(g => g.vesselIds);
      filteredVessels = filteredVessels.filter(v => selectedGroupsVesselIds?.includes(v.id));
    }

    return filteredVessels.filter(v => {
      return v.name.toLowerCase().includes(this.search.toLowerCase());
    });
  }

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get allOpenIncidents(): Incident[] {
    return Incidents.allOpenIncidents;
  }

  get vesselGroups(): VesselGroup[] {
    return VesselGroups.vesselGroups;
  }

  async created(): Promise<void> {
    this.sortBy = localStorage.getItem("vesselOverview_sortBy") ?? "";
    this.sortDesc = localStorage.getItem("vesselOverview_sortDesc") === "true" ?? false;
    const storedItemsPerPage = localStorage.getItem("vesselOverview_itemsPerPage") ?? "10";
    this.itemsPerPage = +storedItemsPerPage;
    this.isMapEnabled = localStorage.getItem("vesselOverview_isMapEnabled") === "true" ?? false;
    const storedGroupIds = localStorage.getItem("vesselOverview_selectedGroupIds");
    if (storedGroupIds) this.selectedGroupIds = JSON.parse(storedGroupIds);
    await VesselGroups.getAll();
    this.areVesselGroupsLoading = false;
    this.vesselCompareNavigateHomeBinding = events.navigatedHome.add(() => {
      this.selectedVessels = [];
      this.showCompare = false;
    });
  }

  @Watch("selectedGroupIds")
  @Watch("search")
  refreshDependentData(): void {
    // This will refresh the map with filtered vessels.
    this.mapKey += 1;
    // Removes selected vessels that aren't in the new filtered list.
    this.selectedVessels = this.selectedVessels.filter(v => this.filteredVessels.includes(v));
  }

  addOrRemoveSelectedVessel(vessel: ExtendedVessel): void {
    const isAlreadySelected = this.selectedVessels.includes(vessel);
    if (!isAlreadySelected) {
      this.selectedVessels.push(vessel);
    } else {
      const index = this.selectedVessels.indexOf(vessel);
      if (index > -1) this.selectedVessels.splice(index, 1);
    }
  }

  selectAllRows(): void {
    if (this.selectedVessels.length < this.filteredVessels.length) this.selectedVessels = this.filteredVessels;
    else this.selectedVessels = [];
  }

  updateSortBy(value: string): void {
    if (!value) value = "";
    localStorage.setItem("vesselOverview_sortBy", value);
    this.sortBy = value;
  }

  updateSortDesc(value: boolean): void {
    if (!value) value = false;
    localStorage.setItem("vesselOverview_sortDesc", value.toString());
    this.sortDesc = value;
  }

  updateItemsPerPage(value: number): void {
    if (!value) value = 10;
    localStorage.setItem("vesselOverview_itemsPerPage", value.toString());
    this.itemsPerPage = value;
  }

  @Watch("isMapEnabled")
  updateStoredIsMapEnabled(isMapEnabled: boolean): void {
    localStorage.setItem("vesselOverview_isMapEnabled", isMapEnabled.toString());
  }

  @Watch("selectedGroupIds")
  updateStoredGroupIds(selectedGroupIds: number[]): void {
    localStorage.setItem("vesselOverview_selectedGroupIds", JSON.stringify(selectedGroupIds));
  }

  getVesselUrl(vessel: Vessel): string {
    return urlHelper.getVesselUrl(vessel);
  }

  getFormattedDate(date: string): string {
    return dateHelper.getFormatedDateTimeString(date);
  }

  getPerformanceStatusText(statusCode: string): string {
    return performanceStatusHelper.getPerformanceStatusText(statusCode);
  }

  getPerformanceStatusIcon(statusCode: string): string {
    return `<img src="${performanceStatusHelper.getPerformanceStatusImageSrc(statusCode)}" height="20" style="margin-bottom: -3px;" class="mr-1">`;
  }

  setVesselIconRotation(course: number): unknown {
    if (!course) return;
    return { transform: `rotate(${course}deg)` };
  }

  isNotificationsEnabled(vessel: ExtendedVessel): boolean {
    if (!vessel) return false;
    return vessel.features.some(feature => feature.name === "Notifications");
  }

  isDiagnosticsEnabled(vessel: ExtendedVessel): boolean {
    if (!vessel) return false;
    return vessel.features.some(feature => feature.name === "Diagnostics");
  }

  getOpenWarningIncidents(vesselId: number): number {
    const openWarningIncidents = this.allOpenIncidents.filter(i => i.vesselId === vesselId && i.severity === "Warning");
    let count = openWarningIncidents.length;
    for (const warningIncident of openWarningIncidents) {
      // If a condition has both an open warning and critical incident, we only want to show the critical one.
      if (this.allOpenIncidents.filter(i => i.conditionId === warningIncident.conditionId && i.severity === "Critical").length > 0) {
        count--;
      }
    }
    return count;
  }

  getOpenCriticalIncidents(vesselId: number): number {
    return this.allOpenIncidents.filter(i => i.vesselId === vesselId && i.severity === "Critical").length;
  }

  hasAnyOpenIncidents(tableRowData: VesselOverviewTableRowData): boolean {
    return tableRowData.openWarningIncidents > 0 || tableRowData.openCriticalIncidents > 0;
  }

  getNotificationsUrl(vessel: ExtendedVessel): string {
    return urlHelper.getVesselNotificationsUrl(vessel);
  }
}

export interface VesselOverviewTableRowData {
  vessel: ExtendedVessel;
  performanceStatus: string | undefined;
  openIncidents: number;
  openWarningIncidents: number;
  openCriticalIncidents: number;
}
