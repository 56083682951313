var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-container',[(!_vm.showCompare)?_c('div',[_c('v-data-table',{attrs:{"dense":"","item-key":"vessel.id","show-select":"","headers":_vm.headers,"items":_vm.tableData,"loading":_vm.areVesselsLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"loading-text":"Loading... Please wait"},on:{"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortDesc,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.areVesselGroupsLoading || _vm.areVesselsLoading)?_c('v-skeleton-loader',{attrs:{"type":"list-item"}}):_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-select',{attrs:{"label":"Filter on groups","items":_vm.vesselGroups,"item-text":"name","item-value":"id","multiple":"","small-chips":"","outlined":"","dense":""},model:{value:(_vm.selectedGroupIds),callback:function ($$v) {_vm.selectedGroupIds=$$v},expression:"selectedGroupIds"}}),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search vessel","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"header.data-table-select",fn:function(){return [_c('v-simple-checkbox',{attrs:{"value":_vm.areAllVesselsSelected},on:{"input":function($event){return _vm.selectAllRows()}}})]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [(header.text === 'Performance Status')?_c('v-tooltip',{key:header.text,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',{staticClass:"primary--text",staticStyle:{"line-height":"1.5rem","font-size":"0.9rem"}},[_vm._v(_vm._s(header.text)+" ")]),_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.performanceStatusTooltipText)}})]):(header.text === 'Last Data Sent')?_c('v-tooltip',{key:header.text,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',{staticClass:"primary--text",staticStyle:{"line-height":"1.5rem","font-size":"0.9rem"}},[_vm._v(_vm._s(header.text)+" ")]),_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Last sent data as reported by the vessel clock")])]):_c('span',{key:header.text,staticClass:"primary--text",staticStyle:{"line-height":"1.5rem","font-size":"0.9rem"},domProps:{"textContent":_vm._s(header.text)}})]},proxy:true}}),{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',{class:index % 2 === 0 ? 'even-row' : 'odd-row'},[_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-simple-checkbox',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"disabled":!_vm.isDiagnosticsEnabled(item.vessel),"value":_vm.selectedVessels.includes(item.vessel)},on:{"input":function($event){return _vm.addOrRemoveSelectedVessel(item.vessel)}}},'v-simple-checkbox',attrs,false),on))]}}],null,true)},[(!_vm.isDiagnosticsEnabled(item.vessel))?_c('span',[_vm._v("Can not select for comparison because diagnostics module is not activated for this vessel")]):_c('span',[_vm._v("Select for comparison")])])],1),_c('td',[_c('router-link',{attrs:{"to":_vm.getVesselUrl(item.vessel)}},[_vm._v(" "+_vm._s(item.vessel.name)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.vessel.lastExportDate))+" ")]),_c('td',[_c('v-layout',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getPerformanceStatusIcon(item.performanceStatus))}}),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.getPerformanceStatusText(item.performanceStatus)))])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c('span',{staticClass:"vesselIcon mr-2",class:item.performanceStatus,style:(_vm.setVesselIconRotation(item.vessel.course))})])],1)],1)],1),_c('td',[(!_vm.isNotificationsEnabled(item.vessel))?_c('span',[_vm._v("Notifications module not activated")]):_c('div',[(!_vm.hasAnyOpenIncidents(item))?_c('v-chip',{attrs:{"to":"/MyNotifications","color":"green","small":"","dark":""}},[_vm._v(" 0 ")]):_vm._e(),(item.openWarningIncidents > 0)?_c('v-chip',{attrs:{"to":"/MyNotifications","color":"orange","small":"","dark":""}},[_vm._v(" "+_vm._s(item.openWarningIncidents)+" ")]):_vm._e(),(item.openCriticalIncidents > 0)?_c('v-chip',{attrs:{"to":"/MyNotifications","color":"red","small":"","dark":""}},[_vm._v(" "+_vm._s(item.openCriticalIncidents)+" ")]):_vm._e()],1)])])]}},{key:"footer.prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-6 font-weight-semibold",attrs:{"color":"accent","disabled":_vm.selectedVessels.length <= 0,"loading":_vm.areVesselsLoading},on:{"click":function($event){_vm.showCompare = !_vm.showCompare}}},'v-btn',attrs,false),on),[_vm._v(" Compare ")])]}}],null,false,2337012229)},[_c('span',[_vm._v("Compare long trends for selected vessels")])]),_c('v-switch',{attrs:{"label":"Show map","disabled":_vm.areVesselsLoading,"loading":_vm.areVesselsLoading},model:{value:(_vm.isMapEnabled),callback:function ($$v) {_vm.isMapEnabled=$$v},expression:"isMapEnabled"}})]},proxy:true}],null,true)}),(!_vm.areVesselsLoading && _vm.isMapEnabled)?_c('Map',{key:_vm.mapKey,staticClass:"mt-5",staticStyle:{"height":"600px"},attrs:{"vessels":_vm.filteredVessels}}):_vm._e()],1):_c('CompareVesselsWrapper',{attrs:{"vessels":_vm.selectedVessels}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }